<template>
  <div id="index">
    <div style="width:600px;" class="top-line">
      <Card>
        <p slot="title">库存预警 <strong v-show="stock_warning.total>0" class="ml-10 main-font-color">{{stock_warning.total}}</strong></p>
        <div style="height:560px;overflow-y: auto;">
          <Table :columns="stock_warning.columns" :data="this.stock_warning.list">
            <template slot-scope="{ row }" slot="stock_up">
              <span v-show="row.stock_up>0" :class="row.stock_up<row.stock_all?'status-danger':''">{{row.stock_up}}</span>
            </template>

            <template slot-scope="{ row }" slot="stock_down">
              <span v-show="row.stock_down>0" :class="row.stock_down>row.stock_all?'status-danger':''">{{row.stock_down}}</span>
            </template>
          </Table>
        </div>
      </Card>
    </div>
    <div class="ml-16">
      <Card style="width:160px" class="pointer top-line">
        <div class="align-center">
          <strong class="fs-64 main-font-color">{{up_count}}</strong>
          <h4>入库待上架数</h4>
        </div>
      </Card>
    </div>
    <div class="ml-16">
      <Card style="width:160px" class="pointer top-line">
        <div class="align-center">
          <strong class="fs-64 main-font-color">{{down_count}}</strong>
          <h4>出库待下架数</h4>
        </div>
      </Card>
    </div>
<!--    <span class="main-color font-douyu">待入库数量、待出库数量、代发货数量、低库存提醒 </span>-->
  </div>
</template>

<script>
    import {apiGetGoodsStockWarning, apiGetGoodsUpDown} from "@/api/goods/goods";

    export default {
      name: "Index",
      data() {
          return {
            stock_warning:{
              list: [],
              total: 0,
              columns: [
                {
                  title: '货品编号',
                  key: 'part_no',
                  align: 'center',
                },
                {
                  title: '货品名称',
                  key: 'part_name',
                  align: 'center',
                },
                {
                  title: '库存上限',
                  key: 'stock_up',
                  slot: 'stock_up',
                  align: 'center',
                },
                {
                  title: '库存下限',
                  key: 'stock_down',
                  slot: 'stock_down',
                  align: 'center',
                },
                {
                  title: '库存',
                  key: 'stock_all',
                  align: 'center',
                },
              ]
            },
            up_count: 0,
            down_count: 0,
          }
      },
      methods:{
        getGoodsStockWarning() {
          apiGetGoodsStockWarning().then(res => {
            if (res.data.err_no ==0) {
              for (let i=0; i<res.data.results.length; i++) {
                res.data.results[i].stock_all = parseFloat(res.data.results[i].stock_all);
              }
              this.stock_warning.list = res.data.results;
              this.stock_warning.total = res.data.results.length;
            }
          })
        },
        getGoodsUpDown() {
          apiGetGoodsUpDown().then(res => {
            if (res.data.err_no ==0) {
              this.down_count = res.data.results.downCount;
              this.up_count = res.data.results.upCount;
            }
          })
        }
      },
      mounted() {
        this.getGoodsStockWarning();
        this.getGoodsUpDown();
      }
    };
</script>

<style scoped>
  #index {
    width:100%;
    height: 100%;
    padding: 20px;
    /*vertical-align:middle; !*垂直居中对齐，vertical-align适用于内联及table-cell元素*!*/
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-size: 64px;
    background-color: #f8f8f9;
    min-height: calc(100vh - 60px);
    display: flex;
  }

  .top-line {
    border-top: 3px solid #2775ff;
    border-radius: 4px;
  }
</style>
