import request from '@/plugins/http'

export function createShipOutByGoodsOutId(param) {
    return request.post('/api/goods/createShipOutByGoodsOutId', param);
}

export function saveImportStockInBack(param) {
    return request.post('/api/goods/saveImportStockInBack', param);
}

export function getGoodsList(param) {
    return request.post('/api/goods/getGoodsList', param);
}

export function apiGetGoodsStockWarning() {
    return request.post('/api/goods/getGoodsStockWarning');
}

export function apiGetGoodsUpDown() {
    return request.post('/api/goods/getGoodsUpDown');
}

export function apiExportGoodsList(param) {
    return request.post('/api/goods/exportGoodsList', param);
}

export function apiGetGoodsStockInfo(param) {
    return request.post('/api/goods/getGoodsStockInfo', param);
}










